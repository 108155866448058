<template>
  <div id="send-payrolls">
    <h2 class="content-title" >Enviar nóminas</h2>
    <div class="content-container" :class="loadingContent ? '' : 'visible'">
      <div v-if="loadedPayrolls.length > 0" class="content-card">
        <div class="send-controls">
          <q-btn :class="currentPayroll == 0 ? 'disabled-btn' : ''" flat round color="primary" icon="west" @click="onClickPrev"  />
          <p class="send-controls__text"> Nómina {{ currentPayroll + 1 }} de {{ loadedPayrolls.length }}</p>
          <q-btn :class="currentPayroll == loadedPayrolls.length - 1 ? 'disabled-btn' : ''" flat round color="primary" icon="east" @click="onClickNext" />
        </div>
        <div class="row">
          <div class="col-sm-4">
            <q-form @submit="onSendPayroll" class="send-form">
              <q-input
                readonly
                class="send-form__input"
                v-model="selectedPayroll.to"
                label="Para"
                placeholder="Para"
              />
              <q-input
                readonly
                class="send-form__input"
                v-model="selectedPayroll.email"
                label="Email"
                placeholder="Email"
              />
              <q-input
                readonly
                class="send-form__input"
                v-model="selectedPayroll.school"
                label="Escuela"
                placeholder="Escuela"
              />
              <q-input
                readonly
                class="send-form__input"
                v-model="selectedPayroll.from"
                label="De"
                placeholder="De"
              />
              <q-input
                class="send-form__input"
                v-model="selectedPayroll.subject"
                label="Asunto"
                placeholder="Asunto"
              />
              <q-input
                class="send-form__input"
                v-model="selectedPayroll.body"
                type="textarea"
                label="Cuerpo del mensaje"
                placeholder="Cuerpo del mensaje"
              />
              <q-btn 
                class="send-form__btn" 
                color="primary"
                :loading="btnSendLoading"
                @click="onSendPayroll"
                label="Enviar"
              />
            </q-form>
          </div>
          <div class="col-sm-8">
            <iframe 
              :src="loadedPayrolls.length ? 'data:application/pdf;base64,' + selectedPayroll.pdf_content: ''"
              width=100% 
              height=600>
            </iframe>
          </div>
        </div>
      </div>
      <div v-else class="content-card">
        <div class="send-controls">
          <p class="send-controls__text">No hay nóminas pendientes</p>
        </div>
        <div class="row">
          <q-icon class="send-smile-icon" name="sentiment_satisfied_alt" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import dictionary from '../../mixins/dictionary'

export default {
  mixins: [dictionary],
  data() {
    return {
      loadingContent: true,
      currentPayroll: 0,
      btnSendLoading: false,
      loadedPayrolls: []
    }
  },

  computed: {
    ...mapGetters('auth', [
      'userAuth'
    ]),
    ...mapGetters('payrolls', [
      'pendingPayrolls'
    ]),
    selectedPayroll() {
      if (this.loadedPayrolls.length > 0) {
        return this.loadedPayrolls.map(payroll => {
          let extraBody  = 'Adjunto paga extra'
          if (payroll.payroll_type != 'payroll') {
            if (payroll.formatted_date.toLowerCase() == 'junio') {
              extraBody += ' de Verano'
            }
            if (payroll.formatted_date.toLowerCase() == 'diciembre') {
              extraBody += ' de Navidad'
            }
          }
          return {
            'to': payroll.firstname + ' ' + payroll.lastname,
            'from': 'administracion@babyteca.es',
            'email': payroll.email,
            'school': payroll.school_name,
            'subject': payroll.payroll_type == 'payroll' ? 'Nómina Babyteca del mes de ' + payroll.formatted_date.toLowerCase() : 'Paga extra Babyteca',
            'body': payroll.payroll_type == 'payroll' ? 'Adjunto nómina mensual del mes de ' + payroll.formatted_date.toLowerCase() : extraBody,
            'pdf_content': payroll.pdf_content
          }
        })[this.currentPayroll]
      } else {
        return {}
      }
    }
  },

  methods: {
    ...mapActions('payrolls', [
      'getPayrolls',
      'getPendingPayrolls'
    ]),

    async onSendPayroll(){
      let payroll = this.loadedPayrolls[this.currentPayroll]
      payroll.name = this.selectedPayroll.to
      payroll.subject = this.selectedPayroll.subject
      payroll.body = this.selectedPayroll.body
      delete payroll.pdf_content
      let form = new FormData()
      let env = this
      form.append('action', 'send')
      form.append('token', this.userAuth)
      form.append('payroll', JSON.stringify(payroll))
      env.btnSendLoading = true
      await axios
      .post('https://www.api.babyteca.es/api/payrolls', form)
      .then(function (response) {
        if (response.data.status == 'ok') {
          env.notify('success', 'ok_send')
          env.loadedPayrolls.splice(env.currentPayroll, 1)
          env.updateData()
        } else {
          env.notify('error', 'ko_send')
        }
        env.btnSendLoading = false
      })
      .catch(function () {
        env.btnSendLoading = false
        env.notify('error', 'ko_send')
      })
    },

    onClickPrev() {
      if (this.currentPayroll - 1 >= 0) {
        this.currentPayroll--
      }
    },

    onClickNext() {
      if (this.currentPayroll + 1 < this.loadedPayrolls.length) {
        this.currentPayroll++
      }
    },

    async updateData() {
      let form = new FormData()
      form.append('token', this.userAuth)
      await this.getPayrolls(form)
      await this.getPendingPayrolls(form)
    }
  },

  async mounted () {
    let env = this
    env.loadingContent = true
    let form = new FormData()
    form.append('token', env.userAuth)
    await env.getPendingPayrolls(form)
    env.loadedPayrolls = env.pendingPayrolls
    env.loadingContent = false
  }
}
</script>
